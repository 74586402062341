/**/
@import './styles/vars.less';

#main-content-layout {
    overflow-x: hidden;
}

.full-screen {
    width: 100%;
    height: 100%;
    background-color: @layout-body-background;
}

hr {
    opacity: 0.4;
}

.unselectable-span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hide-on-demand {
    display: none !important;
}

.code-input {
    font-family: Menlo, Monaco, 'SFMono-Regular', Consolas, 'Liberation Mono',
        Courier, monospace !important;
}

.netdata-field {
    margin-bottom: 20px;
}

.logs-output {
    font-family: Menlo, Monaco, 'SFMono-Regular', Consolas, 'Liberation Mono',
        Courier, monospace !important;
    overflow: scroll;
    height: 250px;
    border: 2px solid @skeleton-color;
    background-color: @background-color-light;
    border-radius: 5px;
    padding: 10px;
    font-size: 90%;
    width: 100%;
}

.logs-output:focus {
    outline: none;
}

.disabled {
    opacity: 0.4;
    cursor: hand;
    pointer-events: none;
}

.one-click-app-card {
    width: 18em;
    margin: 1em;
}

.one-click-app-card img {
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    opacity: 1;
    padding: 24px;
    padding-left: 12px;
    height: 9em;
    width: 100%;
    margin-top: 0;
    -o-object-fit: contain;
    object-fit: contain;
}

.one-click-app-card:hover img {
    opacity: 0.8;
}

.one-click-app-card > div {
    background: @body-background;
}

.inner-card {
    border-width: 1px;
    border-style: solid;
    border-color: @skeleton-color;
    background-color: @body-background;
}

.pre-line-content {
    white-space: pre-wrap;
}
